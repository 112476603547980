import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import HomeSeriesCard from "./HomeSeriesCard";
import { Globals } from "./constants";
import axios from "axios";
import { Link } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import { Telco } from "./telco";
import { canonicalLink } from "./HelperFunctions";

import BackgroundImage from "react-background-image-loader";
// import NotChargedPopUp from "./login/notChargedPopUp";
import {
  VideosListFourByThree,
  InHouseContent,
  Rankings,
  FullWidthCard,
  getCDNUrl,
} from "./HelperFunctions";

import JWPlayerOnScrollViewResize from "./JWPlayerOnScrollViewResize";

import ReactJWPlayer from "react-jw-player";

import { Helmet } from "react-helmet";

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      seriesApiPath: Globals.ApiUrl + "api/v2/series/featured",
      seriesApiResp: [],
      homeApiResp: [],
      currentRankings: "odi",
      customCssTransition: "h-transition",
      isLoading: true,
      page: 1,
      pagesEnd: false,
      hasError: false,
      counter: 0,
    };

    this.isMobile = window.innerWidth < 992;
    this.rankingsChange = this.rankingsChange.bind(this);
    this.getMarkup = this.getMarkup.bind(this);
    this.getFromApi = this.getFromApi.bind(this);
    this.nextPage = this.nextPage.bind(this);
    this.refresh = this.refresh.bind(this);
    this.onDugoutBannerClicked = this.onDugoutBannerClicked.bind(this);

    this.playerReady = this.playerReady.bind(this);
  }

  async componentDidMount() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

    // let url = this.state.seriesApiPath,
    // resp;

    // try{
    //     resp = await axios.get(url)
    // }catch(error){
    //     console.log('error fetching series');
    // }

    // if(resp.status === 200){
    //     this.setState({seriesApiResp: resp.data.featured_series})
    // }else{
    //     console.log('error fetching series!');
    // };

    await this.getFromApi();

    if (this.state.hasError) {
      console.log("Error : 1");
      await this.knockingNetWorking();
      await this.setState(() => {
        return { counter: this.state.counter + 1 };
      });
    }
  }

  //========================================================================
  knockingNetWorking = async () => {
    let cnt = 0;
    for (let i = 0; i < 3; i++) {
      setTimeout(() => {
        this.getFromApi();
        cnt = cnt + 1;
        this.setState(() => {
          return { counter: cnt };
        });
      }, 15000);

      if (this.state.isLoaded) return;
    }
  };

  //==================================End========================================

  refresh() {
    this.setState(
      { isLoading: true, hasError: false, customCssTransition: "h-transition" },
      this.getFromApi
    );
  }

  async getFromApi() {
    console.log("Globals jdnjndjn: ", Globals.telco);
    let homeApiResp,
      homeApiUrl =
        Globals.ApiUrl +
        "api/v3/view_lists/get_by_name?view=home&web_user=1&page=1&telco=" +
        this.props.telco +
        "&app_name=" +
        `${
          localStorage.getItem("domain")
            ? localStorage.getItem("domain")
            : Globals.telco
            ? Globals.appName
            : Globals.appName
        }`;

    try {
      homeApiResp = await axios.get(homeApiUrl);
      this.setState(() => {
        return {
          isLoading: true,
          hasError: false,
          customCssTransition: "h-transition",
        };
      });
    } catch (err) {
      console.log("Error fetching Home Api", err);
      // this.setState({hasError: true, customCssTransition: 'v-transition', isLoading: false})
    }

    homeApiResp && homeApiResp.status === 200 && Array.isArray(homeApiResp.data)
      ? this.setState({
          homeApiResp: homeApiResp.data,
          customCssTransition: "v-transition",
          isLoading: false,
        }) //hide loader after set state
      : this.setState({
          hasError: true,
          customCssTransition: "v-transition",
          isLoading: false,
        });
  }

  rankingsChange(e) {
    if (e.target.id !== this.state.currentRankings)
      this.setState({ currentRankings: e.target.id });
  }

  async nextPage(e) {
    if (!this.state.pagesEnd) {
      let elem = document.getElementsByClassName("loading-more-results");
      if (elem.length) elem[0].classList.remove("d-none");

      const page = this.state.page + 1;
      const url =
        Globals.ApiUrl +
        "api/v3/view_lists/get_by_name?view=home&web_user=1&page=" +
        page.toString() +
        "&telco=" +
        this.props.telco +
        "&app_name=" +
        `${
          localStorage.getItem("domain")
            ? localStorage.getItem("domain")
            : Globals.telco
            ? Globals.appName
            : Globals.appName
        }`;
      let resp;
      try {
        resp = await axios.get(url);
      } catch (err) {
        console.log("error fetching next page");
      }

      if (resp.status === 200 && resp.data.length > 0) {
        if (elem.length) elem[0].classList.add("d-none");

        const homeApiResp = this.state.homeApiResp.concat(resp.data);

        this.setState({ page: page, homeApiResp: homeApiResp });
      } else {
        if (elem.length) elem[0].classList.add("d-none");

        if (resp.data.length === 0) this.setState({ pagesEnd: true });

        console.log("Api Error or no results");
      }
    }
  }

  getMarkup() {
    return this.state.homeApiResp.map((categoryList, i) => {
      switch (categoryList.type) {
        case "series":
          return (
            <HomeSeriesCard
              key={"hms-" + i}
              image={this.bgImage}
              islive={true}
              data={categoryList}
            />
          );
        case "in_house_content":
          return <InHouseContent key={"inhouse-" + i} data={categoryList} />;
        case "ranking":
          return (
            <Rankings
              key={"rankings-" + i}
              data={categoryList}
              currentRankings={this.state.currentRankings}
              rankingsChange={this.rankingsChange}
            />
          );
        case "generic-home":
          return (
            <div key={"generic-section-" + i} className="card mb-2 p-3">
              <FullWidthCard
                type={categoryList.type}
                subType={categoryList.sub_type}
                data={categoryList.data[0]}
              />
            </div>
          );
        case "video":
          return (
            <VideosListFourByThree key={"vids-" + i} data={categoryList} />
          );
        default:
          console.log("Other type occured in home lists", categoryList);
          return "";
      }
    });
  }

  onDugoutBannerClicked() {
    window.gtag("event", "hundred_banner_clicked");
    window.location.href = "/the-hundred-live";
  }

  callGoogleEvent(eventName) {
    window.gtag("event", eventName);
  }

  playerReady(e) {
    if (!this.isMobile) JWPlayerOnScrollViewResize();
  }

  render() {
    const playlist = [
      {
        file: "https://studio.cricwick.net/cricket/league100/playlist.m3u8",
      },
    ];
    return (
      <section
        id="main-section"
        className="px-lg-0 jc-inner-page  align-self-start"
      >
        {Globals.telco !== "mobilink" ? (
          <Helmet>
            <title>
              Live Cricket Score, Schedule, Latest News & Videos |Cricwick
            </title>
            <meta
              name="description"
              content="Get Live Cricket Scores, Scorecard, Schedules, Latest News and Videos of all International Cricket and Premier League Matches on CricWick"
            />
            <meta
              name="facebook-domain-verification"
              content="34sy7gsa8w8lc6l0djeo8nc2cmwml4"
            />
          </Helmet>
        ) : (
          <Helmet>
            <title>
              Live Cricket Streaming & Scores{" "}
              {Globals.product === "Cricwick"
                ? ", Fantasy Cricket, Videos & News"
                : "& News"}{" "}
              | {Globals.product}
            </title>
            <meta
              name="description"
              content={
                "CricWick is a leading fantasy cricket platform offering the fastest score updates, HD live streaming, exclusive videos, interviews & trending news" +
                  Globals.product ===
                "Cricwick"
                  ? "and win cash prizes with Fantasy League only on "
                  : "only on " + Globals.product
              }
            />
          </Helmet>
        )}
        <Helmet>
          <meta charSet="utf-8" />
          <link rel="canonical" href={canonicalLink()} />
        </Helmet>

        <div className="card-banner my-2">
          {Telco === "mobilink" ? (
            <Link to="/fantasy">
              <img
                className="img-fluid"
                src={
                  "https://asset.cricvids.co/cricwick-assets/images/mystar11-inaamaat-copy.png"
                }
                alt=""
              />
            </Link>
          ) : (
            <Link
              to="/fantasy"
              onClick={this.callGoogleEvent("cw_fantasy_banner_clicked")}
            >
              <BackgroundImage
                className="home-sixteen-by-nine"
                src={
                  "https://asset.cricvids.co/cricwick-assets/ads/fantasy_banner_01_27.png"
                }
                placeholder={Globals.placeholderPlain4x3}
              />
            </Link>
          )}

          {/* {Telco === "mobilink" ? (
            <Carousel
            showIndicators={false}
            infiniteLoop={true}
            autoPlay={true}
            showArrows={false}
            showStatus={false}
            showThumbs={false}
          >
            <div className="card">
             
                <img
                  className="img-fluid"
                  src={
                    "https://dtyw1lw4i4r2x.cloudfront.net/banners/TheDugOut_01.jpg"
                  }
                  alt=""
                />
              
            </div>
            <div className="card">
              
                <img
                  className="img-fluid"
                  src={
                    "https://dtyw1lw4i4r2x.cloudfront.net/banners/TheDugOut_02.jpg"
                  }
                  alt=""
                />
              
            </div>
          </Carousel>
          ) : (
            <Carousel
              showIndicators={false}
              infiniteLoop={true}
              autoPlay={true}
              showArrows={false}
              showStatus={false}
              showThumbs={false}
            >
              <div className="card">
                
                  <img
                    className="img-fluid"
                    src={
                      "https://dtyw1lw4i4r2x.cloudfront.net/banners/In-App-Banner.jpg"
                    }
                    alt=""
                  />
                
              </div>
              <div className="card">
                
                  <img
                    className="img-fluid"
                    src={
                      "https://dtyw1lw4i4r2x.cloudfront.net/banners/TheDugOut_02.jpg"
                    }
                    alt=""
                  />
                
              </div>
            </Carousel>
          )} */}
        </div>
        <div className="card-banner my-2">
          <a href="https://bit.ly/cwstcplay" target="_blank">
            <img
              className="img-fluid"
              src={
                "https://asset.cricvids.co/cricwick-assets/images/stc_en_banner.jpg"
              }
              alt="stc-banner"
            />
          </a>
        </div>
        {this.state.isLoading ? (
          <div className="main-loader card min-height-80vh">
            <img
              width="64"
              height="64"
              src={getCDNUrl("/images/loader_v2.gif")}
              alt=""
            />
          </div>
        ) : (
          <div />
        )}

        <div className={this.state.customCssTransition}>
          {this.state.hasError && this.state.counter > 2 ? (
            <div className="has-error card min-height-80vh p-3">
              <h2>
                {" "}
                Error or no result{" "}
                <button className="btn btn-default" onClick={this.refresh}>
                  Try Again
                </button>
              </h2>
            </div>
          ) : (
            this.getMarkup()
          )}

          {!this.state.pagesEnd && this.state.homeApiResp.length ? (
            <div>
              <div className="text-center d-none loading-more-results">
                <img
                  src={getCDNUrl("/images/loader_v2.gif")}
                  height="30"
                  alt=""
                />
              </div>
              <button
                id="more-results"
                onClick={this.nextPage}
                type="button"
                className="btn btn-primary d-none"
              >
                More Results
              </button>
            </div>
          ) : (
            <div />
          )}
        </div>
        {/* <NotChargedPopUp /> */}
      </section>
    );
  }
}

export default Home;
