import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Globals } from "./constants";
import {
  getCDNUrl,
  getUnsubPath,
  getPayWallFlags,
  getRegionFlags,
} from "./HelperFunctions";
import Axios from "axios";
import ConfirmUnsub from "./newLoginFlow/confirmUnsub";
import "./css/cricwick.css";
import "react-dropdown/style.css";
import { Telco } from "./telco";

class Nav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      unsubBtnTxt: "Unsubscribe",
      unsubBtnDisabled: false,
      unsubPath: "",
      unsubPopUp: false,
      editPopUp: false,
      userData: null,
      openUserProfile: false,
      isLoading: true,
      regionFlags: getRegionFlags(localStorage.getItem("countryCode")),
      countryCode: localStorage.getItem("countryCode"),
    };

    this.getUserIcon = this.getUserIcon.bind(this);
    this.getUnsubButton = this.getUnsubButton.bind(this);
    this.getUnsubBtn = this.getUnsubBtn.bind(this);
    this.unsub = this.unsub.bind(this);
    this.userProfile = this.userProfile.bind(this);
  }

  componentDidMount() {
    this.setGoogleAnalyticsId(this.props.userTelco);
    console.log("current page: ", this.props.currentPage);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.countryCode !== this.props.countryCode) {
      this.setState({
        regionFlags: getRegionFlags(this.props.countryCode),
        countryCode: this.props.countryCode,
      });
    }
  }

  authHeader() {
    let token;
    let msisdn;
    let user = JSON.parse(localStorage.getItem("user"));
    token = user.xAccessToken;
    msisdn = user.phone;

    return {
      "x-access-token": token,
      msisdn: msisdn,
      app: localStorage.getItem("domain")
        ? localStorage.getItem("domain")
        : Globals.telco
          ? Globals.appName
          : "",
    };
  }

  getUserProfile = async () => {
    if (this.props.userLoggedIn) {
      const response = await Axios.get(
        `${Globals.UserManagment}api/v1/users/get-user-profile`,
        {
          headers: this.authHeader(),
        }
      );
      if (response.data.status === 1 && response.data.data) {
        let userData = response.data.data;
        this.setState({
          userData: userData,
        });
      }
    } else {
      this.props.history.push("/login");
    }
    //return userData;
  };

  // By Tariq for goole Analatics

  setGoogleAnalyticsId = (telco) => {
    if (telco === "mobilink") {
      window.ga("create", "UA-88965851-3", "auto");
      return;
    } else if (telco === "zain") {
      window.ga("create", "UA-88034149-1", "auto");
      return;
    } else if (telco === "ufone") {
      window.ga("create", "UA-119615143-1", "auto");
      return;
    } else if (telco === "zong") {
      window.ga("create", "UA-141301155-1", "auto");
      return;
    } else {
      window.ga("create", "UA-88034149-3", "auto");
      return;
    }
  };

  setPageUrlForGoogleAL = (url) => {
    let urlPrefix =
      this.props.userTelco === "" ? "cricwick" : this.props.userTelco;
    window.ga("set", "page", `${urlPrefix}` + url);
    window.ga("send", "pageview", `${urlPrefix}` + url);
  };

  // By Tariq for goole Analatics
  handleGoogleAnalytics = (location) => {
    if (window.ga) {
      if (location.pathname === "/login") {
        let url = location.pathname;
        this.setPageUrlForGoogleAL(url);
        return;
      }

      //if (location.search.length == 10) {
      // here we are using search Params, so we concat it to the url string
      let url = location.pathname + location.search;
      this.setPageUrlForGoogleAL(url);
      return;

      //   }
    }
  };

  //===========================

  getTelcoLogo() {
    return Globals.telcoLogo ? (
      <span className="telco-logo ml-3 ml-lg-4">
        <img src={Globals.telcoLogo} alt="" />
      </span>
    ) : (
      ""
    );
  }

  downloadApp = () => {
    let appUrl = Globals.appUrl[this.state.countryCode];
    let sUsrAg = navigator.userAgent,
      win,
      url;
    if (sUsrAg.indexOf("Android") > -1) {
      // url = this.state.appPath;
      url = appUrl["android"];
      window.open(url);
    } else {
      // url = this.state.appPathIos;
      url = appUrl["ios"];
      win = window.open(url, "_blank");
      win.focus();
    }
  };

  getDownloadButton = () => {
    return Globals.telco !== "mobilink" ? (
      <div className={`d-lg-none`}>
        <button
          className="submitHollow1 border-0 btn rounded-0 p-3 text-uppercase font-weight-bold mr-4"
          onClick={this.downloadApp}
          disabled={this.state.btnDisbaled}
        >
          {`Download App`}
        </button>
      </div>
    ) : null;
  };

  userProfile() {
    this.getUserProfile();
    //if (this.props.userLoggedIn) {
    this.props.history.push(`/user-profile`);
    //} else {
    //this.props.history.push(`/login`)
    //}
  }

  getUserIcon() {
    let userlogin = localStorage.getItem("user_logged_in");

    if (userlogin) {
      return (
        <div className=" form-inline ml-auto p0 order-1 order-lg-2">
          <div className={`d-flex align-items-center dropdown`}>
            {this.getDownloadButton()}
            <a
              href="javascript:;"
              className="user"
              data-toggle="dropdown"
              //data-target="#welcomeModal"
            >
              <img src={getCDNUrl("/images/profile_icon.png")} alt="" />
            </a>
            <ul class="dropdown-menu p-3" role="menu" aria-labelledby="menu1">
              <li
                className="mt-1 dropdown-line"
                style={{ cursor: "pointer" }}
                role="presentation"
              >
                <p
                  onClick={
                    this.props.userLoggedIn
                      ? this.userProfile
                      : this.props.history.push("/login")
                  }
                >
                  User Profile
                </p>
              </li>
              {!this.state.regionFlags.unsubEnabledInProfile ? null : (
                <li
                  className="mt-2 dropdown-line"
                  style={{ cursor: "pointer" }}
                  role="presentation"
                >
                  <p
                    onClick={
                      this.props.userLoggedIn
                        ? this.getUnsubBtn
                        : this.props.history.push("/login")
                    }
                  >
                    UnSubscribe
                  </p>
                </li>
              )}
              <li
                className="mt-2"
                role="presentation"
                style={{ cursor: "pointer" }}
              >
                <p role="menuitem" tabindex="-1" onClick={this.logout}>
                  Logout
                </p>
              </li>
            </ul>
            {this.getTelcoLogo()}
          </div>
        </div>
      );
    } else {
      return (
        <div className="form-inline ml-auto p0 order-1 order-lg-2">
          {Globals.telco === "zong" ? (
            <span className="service">Zong Cricket</span>
          ) : (
            ""
          )}
          <div className={`d-flex align-items-center`}>
            {this.getDownloadButton()}
            <Link to="/login" className="user ">
              <img src={getCDNUrl("/images/profile_icon.png")} alt="" />
            </Link>
          </div>

          {this.getTelcoLogo()}
        </div>
      );
    }
  }

  logout(e) {
    localStorage.removeItem("user");
    localStorage.removeItem("user_logged_in");
    localStorage.removeItem("isSocialProfileAttached");
    localStorage.removeItem("countryCode");
    localStorage.removeItem("coins");
    localStorage.removeItem("inviteCode");
    localStorage.removeItem("phoneNumber");
    localStorage.removeItem("domain");
    localStorage.removeItem("id");
    localStorage.removeItem("xAccessToken");
    localStorage.setItem("isMenaDLink", false);
    window.location.href = "/";
  }

  getUnsubButton() {
    if (this.props.userLoggedIn) {
      let unsubPathObj = getUnsubPath(JSON.parse(localStorage.getItem("user")));
      if (unsubPathObj.status === 1 && unsubPathObj.path) {
        return (
          <div className="button">
            <button
              type="button"
              className="btn btn-lg btn-primary allow-login mt-2"
              style={{ position: "relative", zIndex: "1" }}
              unsubpath={unsubPathObj.path}
              onClick={this.unsub}
              disabled={this.state.unsubBtnDisabled}
            >
              {this.state.unsubBtnTxt}
            </button>
          </div>
        );
      }
    }
  }

  getUnsubBtn() {
    if (this.props.userLoggedIn) {
      let unsubPathObj = getUnsubPath(JSON.parse(localStorage.getItem("user")));
      localStorage.setItem("isMenaDLink", false);
      if (unsubPathObj.status === 1 && unsubPathObj.path) {
        return (
          <div className="button">
            {/*<button
              type="button"
              className="btn btn-lg btn-primary allow-login mt-2"
              style={{ position: "relative", zIndex: "1" }}
              unsubpath={unsubPathObj.path}
              onClick={this.unsub}
              disabled={this.state.unsubBtnDisabled}
            >*/}
            <p
              role="menuitem"
              tabindex="-1"
              unsubpath={unsubPathObj.path}
              onClick={this.unsub(unsubPathObj.path)}
              disabled={this.state.unsubBtnDisabled}
            >
              {this.state.unsubBtnTxt}
            </p>
            {/*</button>*/}
          </div>
        );
      }
    }
  }

  async unsub(e) {
    let auth = localStorage.getItem("authToken");
    let url = e; //e.target.getAttribute("unsubpath");
    this.setState(
      { unsubBtnTxt: "Please wait ...", unsubBtnDisabled: true },
      async () => {
        let resp = {},
          user = JSON.parse(localStorage.getItem("user"));
        let authToken = user.authToken ? user.authToken : auth;

        try {
          resp = await Axios.get(url, {
            headers: {
              "auth-token": authToken,
            },
          });
          resp = await Axios.get(
            `${Globals.UserManagment}api/v1/users/unsub-user`,
            {
              headers: {
                "x-access-token": user.xAccessToken,
                msisdn: user.phone,
                app: localStorage.getItem("domain")
                  ? localStorage.getItem("domain")
                  : Globals.telco
                    ? Globals.appName
                    : "",
              },
            }
          );
        } catch {
          console.log("tahir unsub failed");
        }

        this.logout();
      }
    );
  }

  render() {
    this.handleGoogleAnalytics(this.props.location);
    let red = "#FF0000";

    return (
      <>
        <nav
          id="main-nav"
          className="navbar navbar-expand-lg navbar-light py-0 shadow-md mb-2"
        >
          <div className="container">
            <button
              className="navbar-toggler p-0 collapsed"
              type="button"
              data-toggle="collapse"
              data-target="#navbarContent"
            >
              <i className="fa fa-bars" />
            </button>
            <Link
              to="/"
              className="navbar-brand mr-auto pl-3 py-1 p-0 pb-lg-2 pt-lg-1 pl-lg-0 mr-lg-5 order-0"
            >
              {Globals.telco == "mobilink" ? (
                <img className="logo-mb" src={Globals.logo} alt="" />
              ) : (
                <img className="logo" src={Globals.logo} alt="" />
              )}
            </Link>
            {this.getUserIcon()}
            <div
              className="collapse navbar-collapse m-0 p-0 order-2 order-lg-1"
              id="navbarContent"
            >
              <div className="divider" />

              <ul className="navbar-nav ml-lg-4 ml-0">
                <li
                  id="series"
                  className={
                    this.props.currentPage === "/live-cricket-score" ||
                    this.props.currentPage === "/series"
                      ? "active nav-item px-3"
                      : "nav-item px-3"
                  }
                >
                  <Link className="nav-link" to="/live-cricket-score/live">
                    Series{" "}
                    <i
                      className="fa fa-angle-right d-lg-none float-right"
                      aria-hidden="true"
                    />
                  </Link>
                </li>
                <li
                  id="videos"
                  className={
                    this.props.currentPage === "/videos" ||
                    this.props.currentPage === "/video"
                      ? "active nav-item px-3"
                      : "nav-item px-3"
                  }
                >
                  <Link className="nav-link" to="/videos">
                    Videos{" "}
                    <i
                      className="fa fa-angle-right d-lg-none float-right"
                      aria-hidden="true"
                    />
                  </Link>
                </li>
                {Globals.telco !== "mobilink" && (
                  <li
                    id="stories"
                    className={
                      this.props.currentPage === "/stories"
                        ? "active nav-item px-3"
                        : "nav-item px-3"
                    }
                  >
                    <Link className="nav-link" to="/stories">
                      Stories{" "}
                      <i
                        className="fa fa-angle-right d-lg-none float-right"
                        aria-hidden="true"
                      />
                    </Link>
                  </li>
                )}
                <li
                  id="updates"
                  className={
                    this.props.currentPage === "/updates"
                      ? "active nav-item px-3"
                      : "nav-item px-3"
                  }
                >
                  <Link className="nav-link" to="/updates/news">
                    News{" "}
                    <i
                      className="fa fa-angle-right d-lg-none float-right"
                      aria-hidden="true"
                    />
                  </Link>
                </li>
                <li
                  id="rankings"
                  className={
                    this.props.currentPage === "/rankings"
                      ? "active nav-item px-3"
                      : "nav-item px-3"
                  }
                >
                  <Link className="nav-link" to="/rankings/test">
                    Rankings{" "}
                    <i
                      className="fa fa-angle-right d-lg-none float-right"
                      aria-hidden="true"
                    />
                  </Link>
                </li>

                <React.Fragment>
                  {/* {Globals.telco !== "mobilink" && ( */}
                  <li
                    id="wallet"
                    className={
                      this.props.currentPage === "/wallet"
                        ? "active nav-item px-3"
                        : "nav-item px-3"
                    }
                  >
                    <Link className="nav-link" to="/wallet">
                      Wallet{" "}
                      <i
                        className="fa fa-angle-right d-lg-none float-right"
                        aria-hidden="true"
                      />
                    </Link>
                  </li>
                  {/* // )} */}
                  {/* <li
                    id="kpl2022"
                    className={
                      this.props.currentPage === "/series/1465/PJL-2022"
                        ? "active nav-item px-3"
                        : "nav-item px-3"
                    }
                    style={{ fontSize: "16px", fontWeight: "1000" }}
                  >
                    <Link
                      className="nav-link text-danger"
                      to="/series/1465/PJL-2022"
                    >
                      PJL
                      <i
                        className="fa fa-angle-right d-lg-none float-right"
                        aria-hidden="true"
                      />
                    </Link>
                  </li> */}
                  <li
                    id="psl23"
                    className={
                      this.props.currentPage === "/series/1522/Cricket-World-Cup-2023"
                        ? "active nav-item px-3"
                        : "nav-item px-3"
                    }
                    style={{ fontSize: "16px", fontWeight: "1000" }}
                  >
                    <Link
                      className="nav-link text-danger"
                      to="/series/1522/Cricket-World-Cup-2023"
                    >
                      CWC 2023
                      <i
                        className="fa fa-angle-right d-lg-none float-right"
                        aria-hidden="true"
                      />
                    </Link>
                  </li>
                  <li
                    id="fantasy"
                    className={
                      this.props.currentPage === "/fantasy"
                        ? "active nav-item px-3"
                        : "nav-item px-3"
                    }
                  >
                    <Link className="nav-link text-danger" to="/fantasy">
                      MyStars11{" "}
                      <i
                        className="fa fa-angle-right d-lg-none float-right"
                        aria-hidden="true"
                      />
                    </Link>
                  </li>
                </React.Fragment>
              </ul>
            </div>{" "}
          </div>
          <div
            className="modal fade"
            id="welcomeModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog"
              role="document"
              style={{ marginTop: "150px" }}
            >
              <div className="modal-content login-dialogue">
                <a
                  href="javascript:;"
                  className="modal-close text-white"
                  data-dismiss="modal"
                >
                  <i className="fa fa-close" aria-hidden="true" />
                </a>
                <div
                  className="modal-body p-0"
                  style={{ backgroundColor: "#fff" }}
                >
                  <div className="divide2">
                    <div
                      className="divide-content"
                      style={{ minHeight: "auto" }}
                    >
                      <div
                        className="text-center"
                        style={{
                          width: "100%",
                          textAlign: "center",
                          zIndex: "1",
                          paddingBottom: "10px",
                        }}
                      >
                        <div
                          className="text-center"
                          style={{
                            width: "100%",
                            textAlign: "center",
                            zIndex: "1",
                            paddingBottom: "10px",
                          }}
                        >
                          <div className="inner-text-login text-left">
                            <span className="text-jazz-login">
                              {Globals.product}
                            </span>
                            <br />
                            <span className="text-login">Welcome: </span>
                            <span className="status">
                              {this.props.userPhone}
                            </span>
                            <br />
                            <span className="text-login">Status: </span>
                            <span className="status">
                              {this.props.userStatus}
                            </span>
                            <br />
                            {this.props.user &&
                            this.props.user.details &&
                            (this.props.user.details &&
                              this.props.user.details.activated_at) ? (
                              <span>
                                <span className="text-login">
                                  Subscription Date:{" "}
                                </span>
                                <span className="status">
                                  {this.props.user.details.activated_at}
                                </span>
                                <br />
                              </span>
                            ) : (
                              ""
                            )}
                            {this.props.user &&
                            this.props.user.details &&
                            this.props.user.details &&
                            this.props.user.details.last_charged_time ? (
                              <span>
                                <span className="text-login">
                                  Last Renewal:{" "}
                                </span>
                                <span className="status">
                                  {this.props.user.details.last_charged_time}
                                </span>
                                <br />
                              </span>
                            ) : (
                              ""
                            )}
                            {this.props.user &&
                            this.props.user.details &&
                            this.props.user.details &&
                            this.props.user.details.sub_plan ? (
                              <span>
                                <span className="text-login">Price: </span>
                                <span className="status">
                                  {this.props.user.details.sub_plan}
                                </span>
                                <br />
                              </span>
                            ) : (
                              ""
                            )}
                          </div>

                          {/* {Globals.telco !== "mobilink" && this.countryCode === "pk" ? (
                           <div className="button">
                             <button
                               type="button"
                               className="btn btn-lg btn-primary allow-login mt-2"
                               style={{ position: "relative", zIndex: "1" }}
                               data-dismiss="modal"
                               onClick={() =>
                                 this.setState({
                                   unsubPopUp: true,
                                 })
                               }
                             >
                               Change Subscription
                             </button>
                           </div>
                         ) : null} */}
                          {this.getUnsubButton()}

                          <div className="button">
                            <button
                              type="button"
                              className="btn btn-lg btn-primary allow-login mt-2"
                              style={{ position: "relative", zIndex: "1" }}
                              data-dismiss="modal"
                              onClick={this.logout}
                            >
                              Logout
                            </button>
                          </div>
                          <ConfirmUnsub
                            open={this.state.unsubPopUp}
                            changeOpen={(open) =>
                              this.setState({
                                unsubPopUp: open,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*<div
           className="modal fade"
           id="welcomeUserModal"
           tabIndex="-1"
           role="dialog"
           aria-labelledby="exampleModalLabel"
           aria-hidden="true"
         >
           <div
             className="modal-dialog"
             role="document"
             style={{ marginTop: "150px" }}
           >
             <div className="modal-content login-dialogue">
               <div
                 className="modal-body p-0"
                 style={{ backgroundColor: "#fff" }}
               >
                 <div className="">
                   <div className="divide-content" style={{ minHeight: "auto" }}>
                     <div
                       className="text-center"
                       style={{
                         width: "100%",
                         textAlign: "center",
                         zIndex: "1",
                         paddingBottom: "10px",
                       }}
                     >
                       <div
                         className="text-center"
                         style={{
                           width: "100%",
                           textAlign: "center",
                           zIndex: "1",
                           paddingBottom: "10px",
                         }}
                       >
                         <div className="d-flex justify-content-between align-items-center Redbg p-3">
                           <img className="" width={17} height={16} src={arrow} alt="" />
                           <p className="text-white">Account Details</p>
                           <img className="" width={19} height={19} src={edit} alt="" onClick={() =>
                                 this.setState({
                                   editPopUp: true,
                                 })}/>
                         </div>
 
                         <div className="inner-text-login text-left Redbg pt-0">
                           <row className="d-flex pb-3">
                             <img className="rounded-circle" width={60} height={60} src={this.state.userData ? this.state.userData.user.avatarUrl : ""} alt="" />
                             <div className="column ml-3 justify-content-center">
                               <span className="status text-white">{this.state.userData ? this.state.userData.user.name: null}</span>
                               <span className="msisdn text-white">{this.state.userData ? this.state.userData.user.msisdn: null}</span>
                             </div>
                           </row>
                         </div>
                         <EditUser
                           open={this.state.editPopUp}
                           changeOpen={(open) =>
                             this.setState({
                               editPopUp: open,
                             })
                           }
                           //editType={this.state.userData}
                         />
                       </div>
                     </div>
                     <div className="d-flex justify-content-left user-profile">
                       <div className="Greenbg ml-3 mr-3">
                         <div className="user-wallet text-left p-3">
                           <p className="status mb-0 d-flex text-center align-items-center">
                             Wallet
                           </p>
                           <span className="status justify-content-center align-items-center" style={{fontSize: "24px", color: "#136F2F"}}>
                             <img src={wallet} height={24} width={24}></img>
                             {this.state.userData ? this.state.userData.wallet.availableBalance : 0}
                             <span className="m-f-14">pkr</span>
                           </span>
                         </div>
                       </div>
 
                       <span className="status text-left p-3 pt-4">Subscription Details: </span>
 
                       <div className="user text-left pr-3 pl-3">
                         <p>
                           Status
                         </p>
                         <span className="Greenbg text-center pr-2 pl-2 m-f-14" style={{color: "#136F2F", borderRadius: "24px", height: "fit-content"}}>
                           {this.props.userStatus}
                         </span>
                       </div>
 
                       <div className="user text-left pr-3 pl-3">
                         <p>
                           Type
                         </p>
                         <span className="status m-f-14">
                           {this.state.userData ? this.state.userData.user.subscriptionType: "undefined"}
                         </span>
                       </div>
 
                       <div className="user text-left pr-3 pl-3">
                         <p>
                           Terminate sub
                         </p>
                         <span className="status m-f-14" style={{color: "#E15858"}} onClick={this.props.userLoggedIn ?
                         (
                           unsubPathObj1 = getUnsubPath(JSON.parse(localStorage.getItem("user"))),
                           unsubPathObj1.status === 1 && unsubPathObj1.path ? (
                             this.unsub
                           ): null
                         ): null }>
                           Unsubscribe
                         </span>
                       </div>
 
                     </div>
                     <button
                       className="logout btn font-weight-bold m-f-14 mb-3 mt-5"
                       onClick={this.logout}
                     > 
                       {`LOGOUT`}
                     </button>
                   </div>
                 </div>
               </div>
             </div>
           </div>
         </div>*/}
        </nav>
      </>
    );
  }
}

export default withRouter(Nav);
