import React, { Component } from "react";
import Axios from "axios";
import { Link } from "react-router-dom";
import { AdminPannelBaseUrl, Globals } from "./constants";
import BackgroundImage from "react-background-image-loader";
import { Telco } from "./telco";
import {
  space2hyphen,
  space2hyphenForSlug,
  canonicalLink,
} from "./HelperFunctions";

import { Carousel } from "react-responsive-carousel";
import { Helmet } from "react-helmet";
import testright from "./images/test-right.png";
import testright2 from "./images/test-right2.png";
import subscribe from "./images/youtube/subscribe.jpg";
class Rightsection extends Component {
  constructor(props) {
    super(props);
    const images = [testright, testright2];
    this.state = {
      videosPath: `${AdminPannelBaseUrl}/api/videos/category/popularvideos`,
      // videosPath:
      //   Globals.ApiUrl +
      //   "api/v1/user_api/home_videos?telco=" +
      //   this.props.telco +
      //   "&app_name=" +
      //   `${
      //     localStorage.getItem("domain")
      //       ? localStorage.getItem("domain")
      //       : Globals.telco
      //       ? Globals.appName
      //       : Globals.appName
      //   }`,
      // storiesPath:
      //   Globals.ApiUrl +
      //   "api/stories?page=1&per_page=3&app_name=" +
      //   `${
      //     localStorage.getItem("domain")
      //       ? localStorage.getItem("domain")
      //       : Globals.telco
      //       ? Globals.appName
      //       : Globals.appName
      //   }`,
      storiesPath: `${AdminPannelBaseUrl}/api/videos/category/recentvideos`,
      videosApiResp: [],
      storiesResp: [],
      currentImg: 0,
      images,
    };
    this.getVideos = this.getVideos.bind(this);
  }

  async componentDidMount() {
    this.interval = setInterval(() => this.changeImage(), 1000);
  }

  changeImage() {
    let newCurrentImg = 0;
    const { images, currentImg } = this.state;
    const noOfImages = images.length;

    if (currentImg !== noOfImages - 1) {
      newCurrentImg = currentImg + 1;
    }

    this.setState({ currentImg: newCurrentImg });
  }

  async componentWillMount() {
    let url = this.state.videosPath,
      resp;

    try {
      resp = await Axios.get(url);
      if (resp.status == 200) {
        this.setState({ videosApiResp: resp.data });
      }
    } catch (error) {
      console.log("error fetching latest videos");
    }

    this.getStoriesData();
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  async getStoriesData() {
    let resp;

    try {
      resp = await Axios.get(this.state.storiesPath);
      if (resp.status === 200) {
        this.setState({ storiesResp: resp.data });
      }
    } catch (error) {
      console.log("error fetching latest videos");
    }
  }

  getVideos() {
    if (this.state.videosApiResp) {
      return this.state.videosApiResp.map((video, i) => {
        return (
          <VideoCardSm
            key={"vcs-" + i}
            image={this.state.videosImage}
            data={video}
          />
        );
      });
    }
    return "";
  }

  getStories() {
    return this.state.storiesResp.map((story, i) => {
      return (
        <>
          <StoryCardSm
            key={"vcs-" + i}
            image={this.state.videosImage}
            data={story}
          />
        </>
      );
    });
  }

  openUrlInNewTab(url) {
    window.open(url, "_blank");
  }
  onAdChanged(ad) {
    switch (ad) {
      case 0:
        window.gtag("event", "w_right_gago_ludo_watch");
        break;
      case 1:
        window.gtag("event", "w_right_ludo_watch");
        break;

      default:
        break;
    }
  }

  render() {
    const { images, currentImg } = this.state;
    const urlString = images[currentImg];
    return (
      <>
        <div
          id="right-section"
          className="d-none d-lg-block jc-inner-page pl-4"
        >
          <div className="card" style={{ cursor: "pointer" }}>
            <img
              src={subscribe}
              alt="youtube-subscribe"
              onClick={() => this.openUrlInNewTab("https://bit.ly/CricwickYT")}
            />
          </div>
          <div className="card mt-2 p-3">
            <div className="d-flex overflow-x-auto justify-content-between border-bottom align-items-center mb-2">
              <div>
                <p
                  className="font-weight-semi-bold text-uppercase"
                  style={{ fontSize: "1.25rem", fontWeight: "600" }}
                >
                  Most Popular
                </p>
              </div>
              <div>
                {/* <Link to="/videos">
                <h2 className="text-uppercase">View All</h2>
              </Link> */}
              </div>
            </div>
            {this.getVideos()}
          </div>

          {/* {
            Telco === "mobilink"
            ?
            ""
            :
            
            <Carousel className="mt-2" showIndicators={false} infiniteLoop={true} autoPlay={true} showArrows={false} showStatus={false} showThumbs={false} onChange={this.onAdChanged}>  
                <div className="card" onClick={() => {
                  window.gtag("event", "w_right_gago_ludo_click");
                                        window.open(
                                          `http://d.gago.games/pk?utm_source=183&utm_medium=Desktop_Right_Banner_Ludo&utm_campaign=Cricwick&partner_id=2`,
                                          `_blank`
                                        )}}>
                    <img src={'https://asset.cricvids.co/cricwick-assets/ads/Gago/gago_ludo_right.jpg'} alt=""/>
                </div>
               
                <div className="card" onClick={() => {
                  window.gtag("event", "w_right_gago__click");
                                        window.open(
                                          `http://d.gago.games/pk?utm_source=183&utm_medium=Desktop_Right_Banner&utm_campaign=Cricwick&partner_id=2`,
                                          `_blank`
                                        )}}>
                    <img src={'https://asset.cricvids.co/cricwick-assets/ads/Gago/web_gago_new_300_300.png'} alt=""/>
                </div>
                
                <div className="card"  onClick={
                                                    () => {
                                                    window.gtag("event", "w_right_mktv_click");
                                                    window.open(
                                                      `http://mktv.knectapi.com/?utm_source=203&utm_medium=Desktop_Right_Banner_MKTV&partner_id=2`,
                                                      `_blank`
                                                    )}
                                                  }>
                      <img src={'https://asset.cricvids.co/cricwick-assets/ads/mktv/300x300.jpg'}  alt=""/>
                  </div>

            </Carousel>
            
        } */}
          {Telco !== "mobilink" && (
            <>
              <div className="card mt-2 p-3">
                <div className="d-flex overflow-x-auto justify-content-between border-bottom align-items-center mb-2">
                  <div>
                    <p
                      className="font-weight-semi-bold text-uppercase"
                      style={{ fontSize: "1.25rem", fontWeight: "600" }}
                    >
                      Recent Videos
                    </p>
                  </div>
                  <div>
                    {/* <Link to="/stories">
                  <h2 className="text-uppercase">View All</h2>
                </Link> */}
                  </div>
                </div>
                {this.getStories()}
              </div>
            </>
          )}

          {/* <!--card--> */}

          {/* <!--card--> */}
          {/*<div className="card mt-2 p-3">
          <h4 className="font-weight-semi-bold border-bottom pb-2">Match Stats</h4>
          <SeriesStatsTable></SeriesStatsTable>
        </div>*/}
        </div>
      </>
    );
  }
}
const VideoCardDescription = (props) => (
  <div className="custom-clamp-text">
    <p
      className="text-black anchor mb-0 font-weight-normal flex-fill "
      style={{ maxHeight: "3.8rem" }}
    >
      {props.data.title}
    </p>
  </div>
);

const StoryCardDescription = (props) => (
  <div className="custom-clamp-text">
    <h5
      className="text-black mb-0 font-weight-normal flex-fill"
      style={{ maxHeight: "3.8rem" }}
    >
      {props.data.title}
    </h5>
  </div>
);

const VideoCardSm = (props) => (
  <div className="mb-2">
    <p
      onClick={() => {
        window.open(props.data.videoUrl, "_blank");
      }}
      style={{ cursor: "pointer" }}
      className="d-flex "
    >
      <div className="w-40 position-relative">
        {/* <div className="sixteen-by-nine bg-center-cover" style={{backgroundImage: 'url('+props.data.thumb+')'}}></div> */}
        <BackgroundImage
          className="sixteen-by-nine bg-center-cover"
          src={props.data.url}
          placeholder={Globals.placeholderPlain4x3}
        >
          <div className="fa fa-play-circle play-btn-lg play-btn-xs" />
        </BackgroundImage>
      </div>
      <div className="pl-2 video-card-right-section">
        <VideoCardDescription data={props.data} />
      </div>
    </p>
  </div>
);

const StoryCardSm = (props) => (
  <div className="mb-2">
    <p
      className="d-flex "
      onClick={() => {
        window.open(props.data.videoUrl, "_blank");
      }}
      style={{ cursor: "pointer" }}
    >
      <div className="w-40 position-relative">
        {/* <div className="sixteen-by-nine bg-center-cover" style={{backgroundImage: 'url('+props.data.thumb+')'}}></div> */}
        <BackgroundImage
          className="sixteen-by-nine bg-center-cover"
          src={props.data.url}
          placeholder={Globals.placeholderPlain4x3}
        >
          <div className="fa fa-play-circle play-btn-lg play-btn-xs" />
        </BackgroundImage>
      </div>
      <div className="pl-2 video-card-right-section">
        <StoryCardDescription data={props.data} />
      </div>
    </p>
  </div>
);

const SeriesStatsTable = (props) => (
  <table className="mb-3">
    <tbody>
      <SeriesStatsTitle />
      <SeriesStatsRow />
      <SeriesStatsRow />
    </tbody>
  </table>
);

const SeriesStatsTitle = () => (
  <tr>
    <td
      valign="middle"
      className="text-uppercase w-50 font-weight-semi-bold py-2 px-1"
    >
      Most Runs
    </td>
    <td
      valign="middle"
      className="text-secondary font-weight-semi-bold py-2 px-1"
      align="center"
    >
      B
    </td>
    <td
      valign="middle"
      className="text-secondary font-weight-semi-bold py-2 px-1"
      align="center"
    >
      4s
    </td>
    <td
      valign="middle"
      className="text-secondary font-weight-semi-bold py-2 px-1"
      align="center"
    >
      6s
    </td>
    <td
      valign="middle"
      className="text-secondary font-weight-semi-bold py-2 px-1"
      align="center"
    >
      SR
    </td>
  </tr>
);

const SeriesStatsRow = (props) => (
  <tr>
    <td valign="middle" className="font-weight-normal px-1 py-1" align="left">
      Babar Azam
    </td>
    <td valign="middle" className="font-weight-normal px-1 py-1" align="center">
      140
    </td>
    <td valign="middle" className="font-weight-normal px-1 py-1" align="center">
      5
    </td>
    <td valign="middle" className="font-weight-normal px-1 py-1" align="center">
      5
    </td>
    <td valign="middle" className="font-weight-normal px-1 py-1" align="center">
      70.99
    </td>
  </tr>
);

export default Rightsection;
